import axios from '@axios'

export default {
  namespaced: true,
  state: {
    subTypes: [],
    subTypesByType: [],
  },
  getters: {
    getSubTypes(state) {
      return state.subTypes
    },
    getSubTypesByType(state) {
      return state.subTypesByType
    },
  },
  mutations: {
    setSubTypes(state, value) {
      state.subTypes = value
    },
    setSubTypesByType(state, value) {
      state.subTypesByType = value
    },
  },
  actions: {
    fetchAll({ commit }) {
      axios
        .get('/reclamation_sub_types')
        .then(response => {
          const subTypes = response.data['hydra:member'].map(subtype => ({
            value: subtype.id,
            label: subtype.name,
            uri: subtype['@id'],
            name: subtype.name,
            reclamationTypeId: subtype.reclamationType['@id'],
            reclamationType: subtype.reclamationType,
            status: subtype.status,

          }))
          commit('setSubTypes', subTypes)
        })
    },
    fetchAllByType({ commit, state }, uri) {
      commit('setSubTypesByType', state.subTypes.filter(element => element.reclamationTypeId === uri))
    },
    insert({ state, commit }, data) {
      return new Promise((done, fail) => {
        axios
          .post('/reclamation_sub_types', data)
          .then(response => {
            const newSubTypes = state.subTypes
            newSubTypes.push({
              value: response.data.id,
              label: response.data.name,
              uri: response.data['@id'],
              name: response.data.name,
              reclamationTypeId: response.data.reclamationType['@id'],
              reclamationType: response.data.reclamationType,
              status: response.data.status,
            })
            commit('setSubTypes', newSubTypes)
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    patch({ state, commit }, { id, data }) {
      return new Promise((done, fail) => {
        axios.patch(`/reclamation_sub_types/${id}`, data, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(response => {
            const subTypes = []
            state.subTypes.forEach(item => {
              if (item.value === id) {
                subTypes.push({
                  value: response.data.id,
                  label: response.data.name,
                  uri: response.data['@id'],
                  name: response.data.name,
                  reclamationTypeId: response.data.reclamationType['@id'],
                  reclamationType: response.data.reclamationType,
                  status: response.data.status,
                })
              } else {
                subTypes.push(item)
              }
            })
            commit('setSubTypes', subTypes)
            done(true)
          })
          .catch(err => fail(err))
      })
    },
  },
}
