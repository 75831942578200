import axios from '@axios'

export default {
  namespaced: true,
  state: {
    userLogged: {},
    logged: false,
  },
  getters: {},
  mutations: {
    setLogged: (state, value) => {
      state.logged = value
    },
  },
  actions: {
    checkLogin({ commit }, { username, password }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login_check', { username, password })
          .then(response => {
            if (response.status === 200) {
              commit('setLogged', true)
              axios.defaults.headers.Authorization = `Bearer ${JSON.stringify(response.data.token).slice(1, -1)}`
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
