import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import store from '@/store' // eslint-disable-line import/no-cycle

Vue.use(VueI18n)
// eslint-disable-next-line consistent-return
/* function loadLocaleMessages() {
  const messages = {}
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
*/

function getBrowserLocal() {
  const locale = navigator.language.split('-')[0]
  if (['fr', 'en'].includes(locale)) return locale
  return 'en'
}

export default new VueI18n({
  locale: getBrowserLocal(),
  fallbackLocale: getBrowserLocal(),
  messages: {},
})
