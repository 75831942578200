import axios from '@axios'

export default {
  namespaced: true,
  state: {
    users: [],
    insert: false,
    userProfile: {},
    updateUserData: {},
    company: {},
    usersBetrancourt: [],
    allUsers: [],
    filteredUsers: [],
  },
  getters: {
    getusers(state) {
      return state.users
    },
    getUserProfile(state) {
      return state.userProfile
    },
    getInfoCompany(state) {
      return state.company
    },
    getUsersBetrancourt(state) {
      return state.usersBetrancourt
    },
    getAllUsers(state) {
      return state.allUsers
    },
    filteredUser(state) {
      return state.filteredUsers
    },
  },
  mutations: {
    setUsers: (state, value) => {
      state.users = value
    },
    setInsert: (state, value) => {
      state.insert = value
    },
    setUserProfile: (state, data) => {
      state.userProfile = data
    },
    updateUserData: (state, data) => {
      state.upDateUser = data
    },
    setUserDataCompany: (state, data) => {
      state.company = data
    },
    setUsersBetrancourt: (state, value) => {
      state.usersBetrancourt = value
    },
    userDataUpdate: (state, data) => {
      state.updateUserData = data
    },
    setAllUsers: (state, data) => {
      state.allUsers = data
    },
    setFilteredUser: (state, data) => {
      state.filteredUsers = data
    },
  },
  actions: {
    fetchAll({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/users')
          .then(response => {
            let companies = []

            companies = response.data['hydra:member'].map(company => {
              const data = {
                value: company['@id'],
                label: company.name,
                id: company.id,
                company: company.name,
                uri: company['@id'],
                navisCode: company.navisionClientCode,
                adress: company.adress,
                adress2: company.adress2,
                postalCode: company.postalCode,
                city: company.city,
                phone: company.phone,
              }
              return data
            })
            commit('setCompanies', companies)
            done(companies)
          })
          .catch(error => fail(error))
      })
    },
    insert({ commit }, user) {
      return new Promise((done, fail) => {
        axios
          .post('/users', user)
          .then(response => {
            commit('setInsert', true)
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },

    getUserInfos({ commit }, id) {
      return new Promise((done, fail) => {
        axios
          .get(`users/${id}`)
          .then(response => {
            commit('setUserProfile', response.data)
            done(response.data)
          })
          .catch(error => fail(error))
      })
    },

    getInfoCompany({ commit }, data) {
      const url = `companies/${data.company.id}`
      return new Promise((done, fail) => {
        axios
          .get(url)
          .then(response => {
            commit('setUserDataCompany', response.data)
            done(response.data)
          })
          .catch(error => fail(error))
      })
    },
    // eslint-disable-next-line no-empty-pattern
    upDateUserData({ }, data) {
      const url = `/users/${data.id}`
      const userData = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
        password: data.password,
        language: data.language,
      }
      return new Promise((done, fail) => {
        axios.patch(url, userData, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(() => done(true))
          .catch(err => fail(err))
      })
    },
    fetchAllUsersBetrancourt({ commit }) {
      axios
        .get('/users/betrancourt')
        .then(response => commit('setUsersBetrancourt', response.data['hydra:member']))
    },
    fetchAllUsers({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/users')
          .then(response => {
            commit('setAllUsers', response.data['hydra:member'])
            commit('setFilteredUser', response.data['hydra:member'])
            done(response.data['hydra:member'])
          })
          .catch(error => fail(error))
      })
    },
    filter({ commit, state }, user) {
      if (state.allUsers && state.allUsers.length > 0) {
        let filteredUser = []
        filteredUser = state.allUsers.filter(item => user.toLowerCase().split(' ').every(v => item.firstName.toLowerCase().includes(v)))
        if (!filteredUser.length) {
          filteredUser = state.allUsers.filter(item => user.toLowerCase().split(' ').every(v => item.lastName.toLowerCase().includes(v)))
        }
        commit('setFilteredUser', filteredUser)
      }
    },
    updateUserStatus({ state }, data) {
      const url = `/users/${data.id}`
      const userData = { status: false }
      if (data.status === true) {
        userData.status = false
      } else if (data.status === false) {
        userData.status = true
      }
      return new Promise((done, fail) => {
        axios.patch(url, userData, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(response => {
            // eslint-disable-next-line array-callback-return
            // eslint-disable-next-line array-callback-return
            state.filteredUsers.filter(item => {
              if (item.id === data.id) {
                // eslint-disable-next-line no-param-reassign
                item.status = userData.status
              }
            })
            done(response)
          })
          .catch(err => fail(err))
      })
    },
  },
}
