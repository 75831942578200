import axios from '@axios' // eslint-disable-line import/no-cycle

export default {
  namespaced: true,
  state: {
    traductions: [],
    inserted: false,
  },
  getters: {
    getTraductions(state) {
      return state.traductions
    },
  },
  mutations: {
    setTraductions(state, value) {
      state.traductions = value
    },
    setInsert(state, value) {
      state.inserted = value
    },
  },
  actions: {
    fetchLocales() {
      return new Promise((done, fail) => {
        axios
          .get('/languages')
          .then(response => {
            done(response.data['hydra:member'])
          })
          .catch(error => fail(error))
      })
    },
    fetchTraductions({ commit }, params) {
      return new Promise((done, fail) => {
        let url = '/traductions'
        if (params) {
          if (params.no_format) {
            url = `${url}?no_format=true`
          }
        }

        axios
          .get(url)
          .then(response => {
            let res = null
            if (response.data['hydra:member']) {
              res = response.data['hydra:member']
              commit('setTraductions', res)
            } else {
              res = response.data
              localStorage.setItem('internationalisation', JSON.stringify(response.data))
            }
            done(res)
          })
          .catch(error => fail(error))
      })
    },
    postTraductions({ commit }, data) {
      return new Promise((done, fail) => {
        axios
          .post('/traductions', data)
          .then(response => {
            // On ajoute les traductions
            localStorage.setItem('internationalisation', JSON.stringify(response.data.traduction_update))
            commit('setInsert', true)
            done(response.data)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
  },
}
