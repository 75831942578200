import axios from '@axios'

export default {
  namespaced: true,
  state: {
    subSubTypes: [],
  },
  getters: {
    getSubSubTypes(state) {
      return state.subSubTypes
    },
  },
  mutations: {
    setSubSubTypes(state, value) {
      state.subSubTypes = value
    },
  },
  actions: {
    fetchAll({ commit }) {
      axios
        .get('/reclamation_sub_sub_types')
        .then(response => {
          const subSubTypes = response.data['hydra:member'].map(subSubType => ({
            value: subSubType.id,
            label: subSubType.name,
            uri: subSubType['@id'],
            name: subSubType.name,
            '@id': subSubType['@id'],
            status: subSubType.status,
          }))
          commit('setSubSubTypes', subSubTypes)
        })
    },
    insert({ state, commit }, data) {
      return new Promise((done, fail) => {
        axios
          .post('/reclamation_sub_sub_types', data)
          .then(response => {
            const newSubSubTypes = state.subSubTypes
            newSubSubTypes.push({
              value: response.data.id,
              label: response.data.name,
              uri: response.data['@id'],
              name: response.data.name,
              '@id': response.data['@id'],
              status: response.data.status,
            })
            commit('setSubSubTypes', newSubSubTypes)
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    patch({ state, commit }, { id, data }) {
      return new Promise((done, fail) => {
        axios.patch(`/reclamation_sub_sub_types/${id}`, data, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(response => {
            const subSubTypes = []
            state.subSubTypes.forEach(item => {
              if (item.value === id) {
                subSubTypes.push({
                  value: response.data.id,
                  label: response.data.name,
                  uri: response.data['@id'],
                  name: response.data.name,
                  '@id': response.data['@id'],
                  status: response.data.status,
                })
              } else {
                subSubTypes.push(item)
              }
            })
            commit('setSubSubTypes', subSubTypes)
            done(true)
          })
          .catch(err => fail(err))
      })
    },
  },
}
