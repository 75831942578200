export default [
  {
    path: '/admin/user/new',
    name: 'newUtilisateur',
    component: () => import('@/views/backoffice/user/new.vue'),
  },
  {
    path: '/admin/settings',
    name: 'parametrage',
    component: () => import('@/views/backoffice/parametrage.vue'),
  },
  {
    path: '/admin/company/import',
    name: 'importCompany',
    component: () => import('@/views/backoffice/company/import.vue'),
  },
  {
    path: '/admin/company',
    name: 'companyListing',
    component: () => import('@/views/backoffice/company/list.vue'),
  },
  {
    path: '/admin/company/new',
    name: 'newCompany',
    component: () => import('@/views/backoffice/company/new.vue'),
  },
  {
    path: '/admin/company/:id',
    name: 'detailsCompany',
    component: () => import('@/views/backoffice/company/details/details.vue'),
  },
  {
    path: '/admin/users',
    name: 'usersListing',
    component: () => import('@/views/backoffice/user/list.vue'),
  },
  {
    path: '/admin/traduction/',
    name: 'traduction',
    component: () => import('@/views/backoffice/traduction/traduction.vue'),
  },
  {
    path: '/admin/traduction/new',
    name: 'newTraduction',
    component: () => import('@/views/backoffice/traduction/new.vue'),
  },

]
