import axios from '@axios'

export default {
  namespaced: true,
  state: {
    types: [],
    selectedType: null,
    subType: [],
  },
  getters: {
    getTypes(state) {
      return state.types
    },
  },
  mutations: {
    setTypes(state, value) {
      state.types = value
    },
  },
  actions: {
    fetchAll({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/reclamation_types')
          .then(response => {
            const types = response.data['hydra:member'].map(type => ({
              value: type.id, label: type.name, uri: type['@id'], name: type.name, '@id': type['@id'], status: type.status,
            }))
            commit('setTypes', types)
            done(true)
          })
          .catch(e => fail(e))
      })
    },
    insert({ state, commit }, data) {
      return new Promise((done, fail) => {
        axios
          .post('/reclamation_types', data)
          .then(response => {
            const newTypes = state.types
            newTypes.push({
              value: response.data.id,
              label: response.data.name,
              uri: response.data['@id'],
              name: response.data.name,
              '@id': response.data['@id'],
              status: response.data.status,
            })
            commit('setTypes', newTypes)
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    patch({ state, commit }, { id, data }) {
      return new Promise((done, fail) => {
        axios.patch(`/reclamation_types/${id}`, data, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(response => {
            const types = []
            state.types.forEach(item => {
              if (item.value === id) {
                types.push({
                  value: response.data.id,
                  label: response.data.name,
                  uri: response.data['@id'],
                  name: response.data.name,
                  '@id': response.data['@id'],
                  status: response.data.status,
                })
              } else {
                types.push(item)
              }
            })
            commit('setTypes', types)
            done(true)
          })
          .catch(err => fail(err))
      })
    },
  },
}
