import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import jwt from './modules/jwt'
import companies from './modules/companies'
import users from './modules/users'
import reclamations from './modules/reclamations'
import reclamationType from './modules/reclamationType'
import reclamationSubType from './modules/reclamationSubType'
import reclamationSubSubType from './modules/reclamationSubSubType'
import localeTraduction from './modules/localeTraduction'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    jwt,
    companies,
    users,
    reclamations,
    reclamationType,
    reclamationSubType,
    localeTraduction,
    reclamationSubSubType,
  },
  strict: false,
})
