import axios from '@axios'

export default {
  namespaced: true,
  state: {
    reclamations: [],
    filteredReclamation: [],
    totalItems: 0,
    reclamation: {},
    reclamationCommentaries: [],
    stats: {
      all: 0,
      closed: 0,
      inProgress: 0,
      noAssigned: 0,
      resolved: 0,
    },
    statsCompany: {
      all: 0,
    },
    inserted: false,
  },
  getters: {
    listReclamations(state) {
      return state.filteredReclamation
    },
    reclamation(state) {
      return state.reclamation
    },
    getStats(state) {
      return state.stats
    },
    getStatsCompany(state) {
      return state.statsCompany
    },
    getReclamationCommentaries(state) {
      return state.reclamationCommentaries
    },
  },
  mutations: {
    setReclamations: (state, value) => {
      state.reclamations = value
      state.filteredReclamation = value
    },
    setFilteredReclamation: (state, value) => {
      state.filteredReclamation = value
    },
    setReclamation: (state, value) => {
      state.reclamation = value
    },
    setReclamationCommentaries: (state, value) => {
      state.reclamationCommentaries = value
    },
    setStats(state, value) {
      state.stats = value
      // localStorage.setItem('notificationStats', JSON.stringify(value))
    },
    setStatsCompany(state, value) {
      state.statsCompany = value
    },
    setInsert(state, value) {
      state.inserted = value
    },
  },
  actions: {
    stats({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/reclamations/stats')
          .then(response => {
            commit('setStats', response.data)
            done(response.data)
          })
          .catch(error => fail(error))
      })
    },
    statsCompany({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/reclamations/statsCompany')
          .then(response => {
            commit('setStatsCompany', response.data)
            done(response.data)
          })
          .catch(error => fail(error))
      })
    },
    last7DaysReclamations({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/last7DaysReclamations')
          .then(response => {
            commit('setReclamations', response.data['hydra:member'])
            // commit('setTotalItems', hydra:totalItems)
            done(response.data['hydra:member'])
          })
          .catch(error => fail(error))
      })
    },
    fetchAll({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/reclamations')
          .then(response => {
            commit('setReclamations', response.data['hydra:member'])
            done(true)
          })
          .catch(error => fail(error))
      })
    },
    fetchMyReclamation({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/myReclamation')
          .then(response => {
            commit('setReclamations', response.data['hydra:member'])
            done(true)
          })
          .catch(error => fail(error))
      })
    },
    fetchOne({ commit }, id) {
      return new Promise((done, fail) => {
        axios
          .get(`/reclamations/${id}`)
          .then(response => {
            commit('setReclamation', response.data)
            done(response.data)
          })
          .catch(error => fail(error))
      })
    },
    filter({ commit, state }, condition) {
      if (condition.status || condition.type || condition.createFrom || condition.createTo) {
        const filteredReclamation = state.reclamations.filter(reclamation => {
          if (condition.status && condition.status === reclamation.status) return true
          if (condition.type && condition.type === reclamation.reclamationType.id) return true

          if (condition.createFrom && condition.createTo) {
            if (new Date(reclamation.createdAt) >= new Date(condition.createFrom) && new Date(reclamation.createdAt) <= new Date(condition.createTo)) return true
          } else if (condition.createFrom && new Date(reclamation.createdAt) >= new Date(condition.createFrom)) {
            return true
          } else if (condition.createTo && new Date(reclamation.createdAt) <= new Date(condition.createTo)) {
            return true
          }
          return false
        })
        commit('setFilteredReclamation', filteredReclamation)
      } else {
        commit('setFilteredReclamation', state.reclamations)
      }
    },
    insert({ commit }, formData) {
      return new Promise((done, fail) => {
        axios
          .post('/reclamations', formData, { Headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
            commit('setInsert', true)
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    getCommentaries({ commit }, id) {
      return new Promise((done, fail) => {
        axios
          .get(`/commentaries?id_reclamation=${id}`)
          .then(response => {
            commit('setReclamationCommentaries', response.data['hydra:member'])
            done(true)
          })
          .catch(error => fail(error))
      })
    },
    postCommentary({ commit }, { reclamation, commentary, internal }) {
      return new Promise((done, fail) => {
        axios
          .post('/commentaries', { reclamation, commentary, internal })
          .then(response => {
            commit('setInsert', true)
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    patch({ commit }, formData) {
      return new Promise((done, fail) => {
        axios
          .post('/reclamations/update', formData, { Headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
            commit('setInsert', true)
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    update({ commit }, { idReclamation, data, rating }) {
      return new Promise((done, fail) => {
        const params = { function: data }
        if (rating) params.rating = rating
        axios
          .patch(`/reclamations/${idReclamation}/update`, params, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(r => {
            commit('setReclamation', r.data)
            done(true)
          })
          .catch(error => fail(error))
      })
    },
    // eslint-disable-next-line no-empty-pattern
    getPj({}, file) {
      const fileInfo = file
      axios
        .get(`/reclamation_files/${file.id}`, { responseType: 'blob' })
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', fileInfo.name)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },
    global_stats() {
      return new Promise((done, fail) => {
        axios
          .get('/reclamations/global_stats')
          .then(r => done(r))
          .catch(error => fail(error))
      })
    },
  },
}
