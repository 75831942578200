import axios from '@axios'

export default {
  namespaced: true,
  state: {
    companies: [],
    usersByCompany: [],
    filteredCompany: [],
    companiesDetails: [],
    userDataUpdate: [],
    pays: [],
  },
  getters: {
    getCompanies(state) {
      return state.companies
    },
    filteredCompany(state) {
      return state.filteredCompany
    },
    getUsersByCompany(state) {
      return state.usersByCompany
    },
    getPays(state) {
      return state.pays
    },
  },
  mutations: {
    setCompanies: (state, value) => {
      state.companies = value
    },
    setUsersByCompany: (state, value) => {
      state.usersByCompany = value
    },
    setFilteredCompany: (state, data) => {
      state.filteredCompany = data
    },
    companiesDetails: (state, data) => {
      state.companiesDetails = data
    },
    userDataUpdate: (state, data) => {
      state.userDataUpdate = data
    },
    setPays(state, data) {
      state.pays = data
    },
  },
  actions: {
    fetchAll({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/companies')
          .then(response => {
            let companies = []
            companies = response.data['hydra:member'].map(company => {
              const data = {
                value: company['@id'],
                label: company.name,
                id: company.id,
                company: company.name,
                uri: company['@id'],
                navisCode: company.navisionClientCode,
                adress: company.adress,
                adress2: company.adress2,
                postalCode: company.postalCode,
                city: company.city,
                pays: company.pays.name,
                phone: company.phone,
                users: company.users,
                emailContact: company.emailContact,
                createdAt: company.createdAt,
                collaborateurs: company.users.length,

              }
              return data
            })
            commit('setCompanies', companies)
            commit('setFilteredCompany', companies)
            done(companies)
          })
          .catch(error => fail(error))
      })
    },
    fetchAllUsersByCompany({ commit, state }, company) {
      if (company) {
        if (company.users) {
          commit('setUsersByCompany', company.users.filter(user => user.status === true))
        } else {
          const comp = state.companies.filter(el => el.id === company.id)[0]
          commit('setUsersByCompany', comp.users.filter(user => user.status === true))
        }
      }
    },
    // eslint-disable-next-line no-empty-pattern
    insert({}, company) {
      return new Promise((done, fail) => {
        axios
          .post('/companies', company)
          .then(response => {
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    importer({}, formData) {
      return new Promise((done, fail) => {
        axios
          .post('/companies/import', formData, { Headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => done(response.data))
          .catch(error => {
            fail(error)
          })
      })
    },
    fetchOne({ commit }, id) {
      const url = `companies/${id}`
      return new Promise((done, fail) => {
        axios.get(url)
          .then(response => {
            commit('companiesDetails', response.data)
            done(response.data)
          })
          .catch(err => fail(err))
      })
    },
    filter({ commit, state }, company) {
      if (state.companies && state.companies.length > 0) {
        const filteredCompany = state.companies.filter(item => company.toLowerCase().split(' ').every(v => item.company.toLowerCase().includes(v)))
        commit('setFilteredCompany', filteredCompany)
      }
    },
    upDateUserData({ commit }, data) {
      const url = `/users/${data.id}`
      const userData = {
        status: data.status,
      }
      axios.patch(url, userData, { headers: { 'Content-Type': 'application/merge-patch+json' } })
        .then(response => { commit('userDataUpdate', response) })
        .catch(err => err)
    },
    fetchAllPays({ commit }) {
      return new Promise((done, fail) => {
        axios
          .get('/pays')
          .then(response => {
            let pays = []
            pays = response.data['hydra:member'].map(element => {
              const data = {
                id: element.id,
                value: element['@id'],
                uri: element['@id'],
                label: element.name,
                pays: element.name,
                codePays: element.codePays,
              }
              return data
            })
            commit('setPays', pays)
            done(pays)
          })
          .catch(error => fail(error))
      })
    },
  },
}
