export default [
  {
    path: '/reclamation/new',
    name: 'newReclamation',
    component: () => import('@/views/reclamation/new.vue'),
  },
  {
    path: '/reclamation/my/:filter',
    name: 'myReclamation',
    component: () => import('@/views/reclamation/list/myReclamation.vue'),
  },
  {
    path: '/reclamation/my',
    name: 'myReclamation',
    component: () => import('@/views/reclamation/list/myReclamation.vue'),
  },
  {
    path: '/Reclamation/all/:filter',
    name: 'allReclamation',
    component: () => import('@/views/reclamation/list/allReclamation.vue'),
  },
  {
    path: '/Reclamation/all',
    name: 'allReclamation',
    component: () => import('@/views/reclamation/list/allReclamation.vue'),
  },
  {
    path: '/reclamation/:id',
    name: 'detailsReclamation',
    component: () => import('@/views/reclamation/details.vue'),
  },
]
